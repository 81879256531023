// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-layout-updates-js": () => import("./../src/components/layout-updates.js" /* webpackChunkName: "component---src-components-layout-updates-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-estimate-js": () => import("./../src/pages/estimate.js" /* webpackChunkName: "component---src-pages-estimate-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-dropbox-js": () => import("./../src/pages/integrations/dropbox.js" /* webpackChunkName: "component---src-pages-integrations-dropbox-js" */),
  "component---src-pages-period-create-js": () => import("./../src/pages/period/create.js" /* webpackChunkName: "component---src-pages-period-create-js" */),
  "component---src-pages-period-edit-js": () => import("./../src/pages/period/edit.js" /* webpackChunkName: "component---src-pages-period-edit-js" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-settings-credits-js": () => import("./../src/pages/settings/credits.js" /* webpackChunkName: "component---src-pages-settings-credits-js" */),
  "component---src-pages-settings-index-js": () => import("./../src/pages/settings/index.js" /* webpackChunkName: "component---src-pages-settings-index-js" */),
  "component---src-pages-settings-line-items-js": () => import("./../src/pages/settings/line-items.js" /* webpackChunkName: "component---src-pages-settings-line-items-js" */),
  "component---src-pages-settings-target-rate-dialog-js": () => import("./../src/pages/settings/TargetRateDialog.js" /* webpackChunkName: "component---src-pages-settings-target-rate-dialog-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-totals-tsx": () => import("./../src/pages/totals.tsx" /* webpackChunkName: "component---src-pages-totals-tsx" */),
  "component---src-pages-updates-js": () => import("./../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-pages-welcome-js": () => import("./../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

