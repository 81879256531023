/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'unstated'

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export const onRouteUpdate = ({ location }) => {
  if (window.appInsights) {
    window.appInsights.trackPageView(
      location.pathname + location.search + location.hash
    )
  }
}
